
import { defineComponent } from 'vue';
import {
  alertController,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonIcon,
  IonImg,
} from '@ionic/vue';
import store from '@/store';
import axios from 'axios';
import router from '@/router';
import { getCookie } from '@/utils/methods/cookieMethods';
import { validateUser, setUserInfo } from '@/utils/methods/userMethods';
import { SingleRecipe } from '@/types/recipeTypes';
import { openToastOptions } from '@/utils/ionCustomHelpers';
import { ToastColor } from '@/types/ionicTypes';
import { getKlimaScoreRanking, calculateKlimaPercent } from '@/utils/methods/scoreMethods';
import { tblVars } from '@/variables/environmentVariables';

export default defineComponent({
  name: 'RecipeCard',
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
    IonButton,
    IonIcon,
    IonImg,
  },
  props: {
    recipeObject: {
      type: Object as () => SingleRecipe,
      required: true,
    },
    isFavorite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tblVars,
    };
  },
  computed: {
    userLoggedIn: () => {
      return store.getters.getLoginState;
    },
    klimaPercent() {
      if (!this.recipeObject || this.recipeObject.co2_emission === 0) return null;
      return calculateKlimaPercent(this.recipeObject.co2_emission);
    },
  },
  methods: {
    getKlimaScoreRanking,
    favoriteRecipeToggle(event: any) {
      event.stopPropagation();
      if (!this.userLoggedIn) {
        this.userLoginPrompt();
      }
      const targetId = event.currentTarget.dataset.recipeid;
      const sessionToken = getCookie('sessionToken');
      if (!sessionToken) return;

      validateUser(sessionToken).then(userId => {
        let ajaxAction;
        let toastTitle = 'Zur Sammlung hinzugefügt';
        let toastColor: ToastColor = 'success';
        if (this.isFavorite) {
          ajaxAction = 'removeUserFavoriteRecipe';
          toastTitle = 'Aus deiner Sammlung entfernt';
          toastColor = 'tertiary';
        } else {
          ajaxAction = 'addUserFavoriteRecipe';
        }
        axios
          .post(`${tblVars.baseApiUrl}/index.php`, {
            headers: {
              authorization: sessionToken,
            },
            action: ajaxAction,
            userId: userId,
            favoriteRecipe: targetId,
          })
          .then(() => {
            setUserInfo(sessionToken, userId);
            openToastOptions(toastTitle, toastColor, 2000, 'top');
          })
          .catch(error => {
            console.error(error);
          });
      });
    },
    async userLoginPrompt() {
      const alert = await alertController.create({
        header: 'Es freut uns, dass dir dieses Rezept gefällt!',
        message: 'Melde dich an, um es in deiner Sammlung zu speichern...',
        buttons: [
          {
            text: 'Konto erstellen',
            role: 'register',
            handler: () => router.push('/profil/registrierung'),
          },
          {
            text: 'zum Login',
            role: 'login',
            handler: () => router.push('/profil/login'),
          },
          {
            text: 'abbrechen',
            role: 'cancel',
          },
        ],
      });
      return alert.present();
    },
  },
});
