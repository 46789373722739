
import { defineComponent } from 'vue';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonProgressBar } from '@ionic/vue';
import PlaceholderIndicator from '@/components/PlaceholderIndicator.vue';
import RecipeCard from '@/components/cards/RecipeCard.vue';
import store from '@/store';
// import { createGesture } from '@ionic/vue';
import router from '@/router';
import axios from 'axios';
import { tblVars } from '@/variables/environmentVariables';

export default defineComponent({
  name: 'SammlungRezepte',
  components: {
    PlaceholderIndicator,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonProgressBar,
    RecipeCard,
  },
  data() {
    return {
      favoriteRecipes: [],
      loading: {
        recipes: true,
      },
    };
  },
  computed: {
    userLoggedIn: () => {
      return store.getters.getLoginState;
    },
    userFavoriteRecipeIds: () => {
      return store.getters.getUserFavoriteRecipes;
    },
  },
  watch: {
    userFavoriteRecipeIds: {
      handler(newIdList) {
        if (newIdList && JSON.parse(newIdList).length > 0) {
          this.fetchRecipeData(JSON.parse(newIdList));
        } else {
          this.favoriteRecipes = [];
          this.loading.recipes = false;
        }
      },
      immediate: true,
    },
  },
  // mounted() {
  //   const gesture = createGesture({
  //     el: this.$el,
  //     direction: 'x',
  //     threshold: 15,
  //     gestureName: 'switch-collection-to-rezepte',
  //     onMove: ev => this.onMoveHandler(ev),
  //   });
  //   gesture.enable();
  // },
  methods: {
    onMoveHandler(ev: any) {
      if (ev.deltaX < -200) {
        router.push('/sammlung/shops');
      }
    },
    fetchRecipeData(favoriteRecipesIdArray: Array<number>) {
      axios
        .post(`${tblVars.baseApiUrl}/index.php`, {
          action: 'getRecipesByIds',
          recipesIds: favoriteRecipesIdArray,
        })
        .then(response => {
          if (response.data === false) throw 'wrong recipesIds provided';
          this.favoriteRecipes = response.data;
          this.loading.recipes = false;
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
});
