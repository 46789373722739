
import { defineComponent } from 'vue';
import { IonButton, IonImg } from '@ionic/vue';

export default defineComponent({
  name: 'PlaceholderIndicator',
  props: {
    image: {
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    linkText: {
      type: String,
      default: 'Los!',
    },
    link: String,
    secondaryLinkText: String,
    secondaryLink: String,
  },
  components: { IonButton, IonImg },
});
